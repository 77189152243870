// Required
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// colores
$rosado: #FF347D;
$celeste: #41C0F6;
$amarillo: #ef9b09;
$azul: #0e2c51;
$azulOscuro: #0e2642;

$custom-colors: (
   "amarillo": $amarillo,
   "azul": $azul,
   "azulOscuro": $azul
);
$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors: map-remove($theme-colors, "primary", "secondary", "light", "dark");

//Generating bg and text color for all base Colors
@each $color, $value in $custom-colors {
   .bg-#{$color} {
      background-color: $value;
   }

   .text-#{$color} {
      color: $value;
   }
}

// body
//$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size: 32px;
//$h3-font-size:                $font-size-base * 1.75 !default;

$body-color: black;

$font-size-base: 1rem;
$font-size-sm: $font-size-base;
$font-size-lg: $font-size-base * 1.25;

$link-color: $azul;
$link-decoration: none;
$link-shade-percentage: 20%;
//$link-hover-color: shift-color($link-color, $link-shade-percentage) !default;
$link-hover-color: $amarillo;
$link-hover-decoration: none;

// varios
$container-padding-x: 32px;
//$border-radius: 48px;

$spacer: 1rem;
$spacers: (
   0: 0,
   1: $spacer * .25,
   2: $spacer * .5,
   3: $spacer,
   4: $spacer * 1.5,
   5: $spacer * 3,
   6: $spacer * 4,
   7: $spacer * 5,
   8: $spacer * 6,
   9: $spacer * 7,
);

// botones
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;
$btn-padding-y: 8px;
$btn-padding-x: 32px;
$btn-border-width: 4px;

// form
$input-border-radius: 0;
$input-border-radius-sm: 0;
$input-border-radius-lg: 0;

$placeholder-opacity-max: .75 !default;
$placeholder-opacity-min: .25 !default;
$input-placeholder-color: rgba(0, 0, 0, .5);

// Breadcrumbs
$breadcrumb-font-size: null !default;
$breadcrumb-padding-y: 0 !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-item-padding-x: .5rem !default;
$breadcrumb-margin-bottom: 1rem !default;
$breadcrumb-bg: null !default;
$breadcrumb-divider-color: var(--#{$prefix}secondary-color) !default;
$breadcrumb-active-color: var(--#{$prefix}secondary-color) !default;
$breadcrumb-divider: quote(">");
$breadcrumb-divider-flipped: $breadcrumb-divider !default;
$breadcrumb-border-radius: null !default;

@import "../../node_modules/bootstrap/scss/bootstrap";
